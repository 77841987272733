// routes/index.tsx
import type { MetaFunction, LoaderFunction } from "@remix-run/node";
import { json, redirect } from "@remix-run/node";
import { getSession } from '~/utils/session.server';
import { useLoaderData } from "@remix-run/react";
import LandingPage from "~/pages/LandingPage";

type LoaderData = {
  brandName: string;
  contactEmail: string;
  websiteUrl: string;
};

export const meta: MetaFunction = () => {
  return [
    { title: "mNet - Revolutionizing Vegetable Distribution" },
    { name: "description", content: "Connecting wholesalers to retailers with cutting-edge technology" },
  ];
};

export const loader: LoaderFunction = async ({ request }) => {
  const host = request.headers.get("host") || "";
  
 
  const session = await getSession(request.headers.get('Cookie'));
  const access_token = session.get('access_token');
  const url = new URL(request.url);
  const returnTo = url.searchParams.get('returnTo');

  if (access_token) {
    return redirect(returnTo ? `/home/dashboard?returnTo=${returnTo}` : '/home/dashboard');
  }

  // const url = new URL("https://farmersmandi.in/");

  const landingPageDomains = ["farmersmandi.in", "mnetlive.com"]

  if(!landingPageDomains.includes(url.hostname)) {
    return redirect(returnTo ? `/login?returnTo=${returnTo}` : "/login");
  }

  const isMnetDomain = host.includes("mnetlive.com");
  const brandData: LoaderData = {
    brandName: isMnetDomain ? "mNet" : "farmersMandi",
    contactEmail: isMnetDomain ? "contact@brihsolutions.in" : "contact@farmersmandi.in",
    websiteUrl: isMnetDomain ? "www.mnetlive.com" : "www.farmersmandi.in"
  };

  return json(brandData);
};

export default function Index() {
  const brandData = useLoaderData<LoaderData>();
  return <LandingPage {...brandData} />;
}
